<template>
  <div class="list">
    <v-row class="ma-5">
      <v-dialog v-model="showDeleteConfirmation" persistent max-width="390">
        <v-card>
          <v-card-title class="text-h5"> Hapus Data? </v-card-title>
          <v-card-text
            >Data akan dihapus secara permanen dan tidak akan bisa dikembalikan.
            <br /><br />
            <span
              >Anda akan dikeluarkan dari aplikasi jika Anda menghapus data Anda
              sendiri.</span
            ></v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="deleteUser"> Hapus </v-btn>
            <v-btn text @click="showDeleteConfirmation = false"> batal </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-btn class="primary ml-2" :to="{ name: 'AddUser' }">Tambah User</v-btn>
    </v-row>
    <v-row>
      <v-card width="100%" class="ma-5" elevation="5">
        <v-card-title>
          Daftar User
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :loading="isLoading"
          loading-text="Loading data..."
          :headers="headers"
          :items="usersList"
          :search="search"
        >
          <template v-slot:[`item.createdDate`]="{ item }">
            {{ localDate(item.createdDate) }}
          </template>
          <template v-slot:[`item.updatedDate`]="{ item }">
            {{ localDate(item.updatedDate) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon class="mr-1" @click="showUser(item)">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
            <v-btn
              icon
              class="mr-1"
              :to="{ name: 'EditUser', params: { id: item.id } }"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              icon
              class="mr-1"
              @click="confirmDelete(item.id)"
              v-if="item.id != 1"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showDeleteConfirmation: false,
      dialog: false,
      isLoading: true,
      search: "",
      selectedPasien: [],
      selectedIdTobeDeleted: 0,
      headers: [
        {
          text: "First Name",
          align: "start",
          value: "firstName",
        },
        {
          text: "Last Name",
          align: "start",
          value: "lastName",
        },
        {
          text: "Username",
          align: "start",
          value: "username",
        },
        {
          text: "Email",
          align: "start",
          value: "email",
        },
        { text: "Created At", value: "createdDate" },
        { text: "Created By", value: "userNameCreate" },
        { text: "Updated At", value: "updatedDate" },
        { text: "Updated By", value: "userNameUpdate" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    usersList() {
      return this.$store.getters.usersList;
    },
  },
  methods: {
    localDate(date) {
      date = new Date(date);
      return Intl.DateTimeFormat("id-ID", { dateStyle: "long" }).format(date);
    },
    refreshData() {
      this.$store.dispatch("getUsersList");
    },
    confirmDelete(id) {
      this.selectedIdTobeDeleted = id;
      this.showDeleteConfirmation = true;
    },
    deleteUser() {
      this.$store
        .dispatch("deleteUser", this.selectedIdTobeDeleted)
        .then(() => {
          this.showDeleteConfirmation = false;
        });
    },
    showUser(item) {
      this.$store.dispatch("getUser", item);
      this.$router.push({ name: "ShowUser" });
    },
  },
  mounted() {
    this.$store
      .dispatch("getUsersList")
      .then(() => {
        this.isLoading = false;
      })
      .catch((error) => {
        console.log("Gagal memuat data user");
        console.log(error);
      });
  },
};
</script>
